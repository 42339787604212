import React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import {
  RiHome2Line,
  RiUser3Line,
  RiFolderInfoLine,
  RiVideoLine,
  RiSettings5Line,
  RiHealthBookLine,
  RiLogoutBoxLine,
  RiExternalLinkLine,
} from 'react-icons/ri';
import ROUTES from '../../../core/constants/routes.constant';
import { useStyles } from './component.styles';
import { useAuth } from '@omnigenbiodata/ui';
import { MICROSITE_URL } from '../../../core/constants/content.constant';

interface SideMenuProps {
  isOpen: boolean;
  onToggle: (open: boolean) => (event: any) => void;
  showHealthOption?: boolean;
}

function SideMenu({ isOpen, onToggle, showHealthOption = false }: SideMenuProps) {
  const { signOut } = useAuth();
  const { t } = useTranslation('portal');
  const { classes } = useStyles();
  return (
    <>
      <IconButton onClick={onToggle(true)} edge="start" className={classes.hamburger} data-testid="menu-btn">
        <MenuIcon fontSize="large" />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={onToggle(false)} data-testid="menu-drawer">
        <Box className={classes.drawer} role="presentation" onClick={onToggle(false)} onKeyDown={onToggle(false)}>
          <List>
            <ListItem
              data-testid="nav-item"
              button
              key={t('home.homeLink') as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.portal}
            >
              <ListItemIcon>
                <RiHome2Line fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.homeLink')} />
            </ListItem>
            {showHealthOption && (
              <ListItem
                data-testid="nav-item"
                button
                key={t('home.healthInfoLink') as string}
                component={Link}
                onClick={() => {
                  onToggle(false);
                }}
                to={ROUTES.portalHealth}
              >
                <ListItemIcon>
                  <RiHealthBookLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
                </ListItemIcon>
                <ListItemText primary={t('home.healthInfoLink')} />
              </ListItem>
            )}
            <ListItem
              data-testid="nav-item"
              button
              key={t('home.personalInfoLink') as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.portalPersonal}
            >
              <ListItemIcon>
                <RiUser3Line fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.personalInfoLink')} />
            </ListItem>
            <ListItem
              data-testid="nav-item"
              button
              key={t('home.preferencesLink') as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.portalPrefs}
            >
              <ListItemIcon>
                <RiSettings5Line fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.preferencesLink')} />
            </ListItem>

            <ListItem
              data-testid="nav-item"
              button
              key={t('home.pisLink') as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.portalInfo}
            >
              <ListItemIcon>
                <RiFolderInfoLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.pisLink') as string} />
            </ListItem>
            <ListItem
              data-testid="nav-item"
              button
              key={t('home.videoLink') as string}
              component={Link}
              onClick={() => {
                onToggle(false);
              }}
              to={ROUTES.portalVideo}
            >
              <ListItemIcon>
                <RiVideoLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.videoLink') as string} />
            </ListItem>
            <Divider />
            <ListItem
              data-testid="nav-item"
              button
              key={t('home.faqsLink') as string}
              component={'a'}
              onClick={() => {
                onToggle(false);
              }}
              href={`${MICROSITE_URL}#dmsaFaqsWrapper`}
              target="_blank"
            >
              <ListItemIcon>
                <RiExternalLinkLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.faqsLink') as string} />
            </ListItem>

            <ListItem
              data-testid="nav-item"
              button
              key={t('home.contactLink') as string}
              component={'a'}
              onClick={() => {
                onToggle(false);
              }}
              href={`${MICROSITE_URL}#contact`}
              target="_blank"
            >
              <ListItemIcon>
                <RiExternalLinkLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.contactLink') as string} />
            </ListItem>
            <Divider />
            <ListItem
              data-testid="nav-logout"
              button
              key={t('home.logoutLink') as string}
              onClick={() => {
                signOut();
              }}
            >
              <ListItemIcon>
                <RiLogoutBoxLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
              </ListItemIcon>
              <ListItemText primary={t('home.logoutLink') as string} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default SideMenu;
