import { RootState } from '../index';
import { EhrStatus, Visits } from '../../core/types/health.types';
import { StoreStatuses } from '../../core/types/common.types';

export const visitsSelector = (state: RootState): Visits[] | null => state.health.visits;

export const isBusySelector = (state: RootState): boolean => state.health.status === StoreStatuses.BUSY;

export const healthErrorSelector = (state: RootState): boolean => state.health.error;

export const ehrStatusSelector = (state: RootState): EhrStatus => state.health.ehrStatus;
