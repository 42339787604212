import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Loader, useMatomo } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import MainLayout from '../../../../layout/Main';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { getConsentFormLinkThunk, updateMyProfileThunk } from '../../../../store/participant/';
import {
  consentFormLinkSelector,
  profileSelector,
  hasErrorSelector,
  isBusySelector,
  isConsentFormBusySelector,
  hasConsentFormErrorSelector,
} from '../../../../store/participant/selectors';

import DetailsUpdateForm from './components/DetailsUpdateForm';

function PersonalScene() {
  const { trackEvent } = useMatomo();
  const { t } = useTranslation(['portal', 'validation']);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasInitiatedDownload, setHasInitiatedDownload] = useState(false);
  const profile = useAppSelector(profileSelector);
  const signedPDFUrl = useAppSelector(consentFormLinkSelector);
  const isBusy = useAppSelector(isBusySelector);
  const isConsentBusy = useAppSelector(isConsentFormBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const hasConsentError = useAppSelector(hasConsentFormErrorSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (signedPDFUrl && hasInitiatedDownload) {
      window.open(signedPDFUrl, '_blank');
    }
  }, [signedPDFUrl, hasInitiatedDownload]);

  const handleConsentFormDownload = () => {
    setHasInitiatedDownload(true);
    dispatch(getConsentFormLinkThunk());
    trackEvent({ category: 'consent-form', action: 'download' });
  };

  const handleDetailsUpdate = (values: any) => {
    setHasSubmitted(true);
    dispatch(updateMyProfileThunk(values));
  };

  return (
    <>
      <MainLayout>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {t('personal.title')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('personal.intro')}
        </Typography>

        <Box mb={2}>
          {hasSubmitted && !isBusy && hasError && <Alert severity="error">{t('personal.messages.error')}</Alert>}

          {hasSubmitted && !isBusy && !hasError && <Alert severity="success">{t('personal.messages.success')}</Alert>}
        </Box>

        <DetailsUpdateForm
          onSubmit={handleDetailsUpdate}
          initialValues={{
            address: profile?.address || '',
            sk2: profile?.sk2 || '',
            landline: profile?.landline || '',
            firstName: profile?.firstName || '',
            surname: profile?.surname || '',
            postcode: profile?.postcode || '',
          }}
        />

        <Box mb={2}>
          <Paper variant="outlined">
            <Box p={4}>
              <Typography variant="h6" align="left" gutterBottom>
                <strong>{t('personal.consent.title')}</strong>
              </Typography>

              <Typography variant="body1" paragraph>
                {t('personal.consent.para1')}
              </Typography>

              <Button
                variant="outlined"
                color={'primary'}
                size="large"
                endIcon={<CloudDownloadIcon />}
                onClick={handleConsentFormDownload}
                data-testid="downloadPdf"
              >
                <div>
                  {!isConsentBusy && t('personal.consent.button')}
                  {isConsentBusy && t('personal.downloading')}
                  {!isConsentBusy && hasConsentError && hasInitiatedDownload && (
                    <Typography variant="body2" color="error">
                      {t('personal.consent.error')}
                    </Typography>
                  )}
                </div>
              </Button>
            </Box>
          </Paper>
        </Box>
      </MainLayout>
      <Loader isVisible={isBusy} label={t('personal.saving')} />
    </>
  );
}

export default PersonalScene;
