import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { theme } from '@omnigenbiodata/ui';

interface EventCardProps {
  date: string;
  title: string;
  subtitle?: string;
  text?: string;
}

function EventCard({ date, title, subtitle, text }: EventCardProps) {
  return (
    <Paper>
      <Box mt={2} p={3} border="1px solid" borderColor="primary.lighter">
        <Typography variant="subtitle1">{date}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.9 }} color={theme.palette.primary.main} fontWeight="bold">
          {title}
        </Typography>
        {subtitle && <Typography variant="body2">{subtitle}</Typography>}
        {text && (
          <Typography variant="body2" fontWeight="bold">
            {text}
          </Typography>
        )}
      </Box>
    </Paper>
  );
}

export default EventCard;
