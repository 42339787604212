import { API as AMPLIFY_API } from 'aws-amplify';
import { API as MOCK_AMPLIFY_API } from '../../__mocks__/aws-amplify';
import { getVisitsQuery, getEhrStatusQuery } from '../graphql/queries';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const healthApi = {
  getVisits: async (): Promise<any> => {
    try {
      return await API.graphql({
        query: getVisitsQuery,
      });
    } catch (error) {
      return error;
    }
  },
  getEhrStatus: async (): Promise<any> => {
    try {
      return await API.graphql({
        query: getEhrStatusQuery,
      });
    } catch (error) {
      return error;
    }
  },
};

export default healthApi;
