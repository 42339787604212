import React, { useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../../../layout/Main';
import { healthDataOptInSelector } from '../../../../store/participant/selectors';
import { useAppSelector } from '../../../../store';
import { Loader, Tabs } from '@omnigenbiodata/ui';
import { ROUTES } from '../../../../core/constants/routes.constant';
import { useAppDispatch } from '../../../../store';
import Visits from './components/Visits';
import { getEhrStatusThunk, getVisitsThunk } from '../../../../store/health';
import {
  ehrStatusSelector,
  isBusySelector,
  visitsSelector,
  healthErrorSelector,
} from '../../../../store/health/selectors';

function HealthScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const healthDataOptIn = useAppSelector(healthDataOptInSelector);
  const isBusy = useAppSelector(isBusySelector);
  const visits = useAppSelector(visitsSelector);
  const hasError = useAppSelector(healthErrorSelector);
  const ehrStatus = useAppSelector(ehrStatusSelector);

  useEffect(() => {
    if (healthDataOptIn) {
      dispatch(getEhrStatusThunk());
    } else navigate(ROUTES.portal);
  }, [dispatch, navigate, healthDataOptIn]);

  useEffect(() => {
    if (ehrStatus.dataHasArrived) {
      dispatch(getVisitsThunk());
    }
  }, [ehrStatus, dispatch]);

  return (
    <MainLayout>
      <Typography variant="h4" mb={1} component="h1" align="center" paragraph>
        {t('health.title')}
      </Typography>
      {ehrStatus.lastProcessedTime && (
        <Typography variant="subtitle2" component="h6" align="center" paragraph>
          {t('health.subtitle')} {moment(ehrStatus.exportedDate).utcOffset('+0200').format('DD/MM/YYYY')}
        </Typography>
      )}
      {!isBusy &&
        (hasError ? (
          <Box mb={2}>
            <Alert severity="error">{t('health.error')}</Alert>
          </Box>
        ) : !ehrStatus.dataHasArrived ? (
          <Box mb={2}>
            <Alert severity="info">{t('health.comingSoon')}</Alert>
          </Box>
        ) : (
          <>
            <Alert severity="success">
              <Typography variant="h6" mb={1} mt={-0.5} component="h4" align="left" paragraph>
                {t('health.watchTitle')}
              </Typography>
              <Typography variant="body1" mb={1} component="p" align="left">
                {t('health.watchText')}
              </Typography>
            </Alert>
            <Tabs tracked>
              <Visits visits={visits} title={t('health.visits.title')} />
            </Tabs>
          </>
        ))}
      <Loader isVisible={isBusy} label={t('health.loader')} />
    </MainLayout>
  );
}

export default HealthScene;
